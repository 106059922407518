// Base of tempate FooterThree

import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import Logo from "./common/Logo";

const SocialShare = [
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/future-management-services-lda",
  },
  // { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  // { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  // { Social: <FaTwitter />, link: "https://twitter.com/" },
];

const FooterThree = (): React.ReactElement => {
  return (
    <div className="footer-style-3 pt--100 pb--30 bg_color--11">
      <div className="wrapper">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-12">
            <div className="inner text-center">
              <Logo whiteVersion={true} />
              <ul className="social-share d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul>
              <div className="text mt--20">
                <p>© 2022 . All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterThree;
