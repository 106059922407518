// Base of tempate HeaderFive

import React, { useEffect, useCallback } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import ReactGA from "react-ga4";

import Logo from "./common/Logo";
import NavLink from "../component/NavLink";
import LangSwitch from "./LangSwitch";

import { navLinks } from "../data/navLinks";

type HeaderProps = {
  color: string;
  headerPosition: string;
};

const Header = (props: HeaderProps): React.ReactElement => {
  const handleMenu = useCallback(() => {
    document.querySelector(".header-wrapper")?.classList.toggle("menu-open");

    ReactGA.event({
      category: "Menu",
      action: "open menu",
    });
  }, []);

  const handleCloseMenu = useCallback(() => {
    document.querySelector(".header-wrapper")?.classList.remove("menu-open");

    ReactGA.event({
      category: "Menu",
      action: "close menu",
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 50) {
        document.querySelector(".header--fixed")?.classList.add("sticky");
      } else {
        document.querySelector(".header--fixed")?.classList.remove("sticky");
      }
    });
  }, []);

  const { color, headerPosition } = props;

  return (
    <header
      className={`header-area header-not-transparent header--fixed formobile-menu ${headerPosition} ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-4 col-6">
              <div className="header-left">
                <div className="logo">
                  <Logo />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-6">
              <div className="header-right justify-content-end">
                <nav className="mainmenunav d-lg-block">
                  <ul className="mainmenu">
                    {navLinks.map(({ navLinkKey, scrollToId }, idx) => (
                      <NavLink
                        key={idx}
                        navLinkKey={navLinkKey}
                        scrollToId={scrollToId}
                      />
                    ))}
                  </ul>
                </nav>
                <LangSwitch />
                {/* Start Humberger Menu  */}
                <div className="humberger-menu d-block d-lg-none pl--20">
                  <span onClick={handleMenu} className="menutrigger text-white">
                    <FiMenu />
                  </span>
                </div>
                {/* End Humberger Menu  */}
                <div className="close-menu d-block d-lg-none">
                  <span onClick={handleCloseMenu} className="closeTrigger">
                    <FiX />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
