import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { useNav } from "../hooks/useNav";

const slideStickProps = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  fade: true,
  easing: "fade",
  adaptiveHeight: true,
};

const Home = (): React.ReactElement => {
  const { t } = useTranslation();
  // useNav takes in a navLinkId and returns a ref
  // this ref is used to register the navLinkId that's
  // currently in view, and apply activeClass styling
  // to the corresponding nav childElement

  const homeRef = useNav("Home");
  const SlideList = [
    {
      textPosition: "text-left",
      bgImage: "bg_image--1",
      title: t("home.slider.1.title"),
    },
  ];

  return (
    <div className="slider-wrapper" ref={homeRef} id="homeContainer">
      <div className="slider-activation">
        <Slider className="rn-slick-dot dot-light" {...slideStickProps}>
          {SlideList.map((value, index) => (
            <div
              className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
              key={index}
              data-black-overlay="2"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.title && <h1 className="title ">{value.title}</h1>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Home;
