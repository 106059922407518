import React from "react";
import { useTranslation } from "react-i18next";

import { useNav } from "../hooks/useNav";

const iframeContainerStyle = { height: "550px", width: "100%" };
const iframeStyle = { border: 0 };

const Contact = (): React.ReactElement => {
  const { t } = useTranslation();

  // useNav takes in a navLinkId and returns a ref
  // this ref is used to register the navLinkId that's
  // currently in view, and apply activeClass styling
  // to the corresponding nav childElement

  const contactRef = useNav("Contact");

  return (
    <div ref={contactRef} id="contactContainer">
      <div className="rn-contact-map-wrapper ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="rn-contact-map-area position-relative">
                <h3 className="title">{t("contact.title")}</h3>
                <p className="description">
                  <div>{t("contact.street")}</div>
                  <br />

                  <div>{t("contact.postal-code")}</div>

                  <br />
                  <div>{t("contact.city")}</div>
                  <br />

                  <div>{t("contact.country")}</div>
                  <br />
                  <div>{t("contact.phone")}</div>
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("contact.email"),
                  }}
                />
                <br />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="rn-contact-map-area position-relative">
                <div style={iframeContainerStyle}>
                  <iframe
                    title={"Google map"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d751.0797049616248!2d-8.672318511874298!3d41.14939400854618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f9b59ddd8d1%3A0xe50983faf7f3962e!2sR.%20Bela%208%2C%204150-574%20Porto%2C%20Portugal!5e0!3m2!1sen!2sie!4v1646052836261!5m2!1sen!2sie"
                    width="100%"
                    height="450"
                    style={iframeStyle}
                    allowFullScreen={false}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
