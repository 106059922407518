// Base of tempate TeamOne

import React from "react";
import { useTranslation } from "react-i18next";
import data from "../data/team/data";

type TeamProps = {
  column: string;
  teamStyle: string;
};

const TeamMember = (props: TeamProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="row">
      {data.map((value, i) => (
        <div className={`${props.column}`} key={i}>
          <div className={`team ${props.teamStyle}`}>
            <div className="thumbnail">
              <img
                className="w-100"
                src={`/assets/images/team/team-${value.images}.jpg`}
                alt={t(value.title)}
              />
            </div>
            <div className="content">
              <h5 className="title">{t(value.title)}</h5>
              <p className="designation">{t(value.designation)}</p>
            </div>
            <ul className="social-icon">
              {value.socialNetwork.map((social, index) => (
                <li key={index}>
                  <a href={`${social.url}`}>{social.icon}</a>
                </li>
              ))}
            </ul>
          </div>
          <p className="ml--30 mr--30 mt--30 text-justify">
            {t(value.description)}
          </p>
        </div>
      ))}
    </div>
  );
};
export default TeamMember;
