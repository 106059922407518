import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

let data = [
    {
        images: '01',
        title: 'team.member.1.name',
        designation: 'team.member.1.responsibility',
        description: 'team.member.1.description',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    },
    {
        images: '02',
        title: 'team.member.2.name',
        designation: 'team.member.2.responsibility',
        description: 'team.member.2.description',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
        ]
    },
    {
        images: '03',
        title: 'team.member.3.name',
        designation: 'team.member.3.responsibility',
        description: 'team.member.3.description',
        socialNetwork: [
            {
                icon: <FaFacebookF />,
                url: '#'
            },
            {
                icon: <FaLinkedinIn />,
                url: '#'
            },
            {
                icon: <FaTwitter />,
                url: '#'
            },
        ]
    }

];

export default data;