import React from "react";
import { useTranslation } from "react-i18next";
import { MdPsychology, MdSavings, MdEmojiObjects } from "react-icons/md";
import { useNav } from "../hooks/useNav";

const ServiceListOne = [
  {
    icon: <MdPsychology />,
    title: "offering.highly-skilled.title",
    description: "offering.highly-skilled.description",
  },
  {
    icon: <MdSavings />,
    title: "offering.cost-effective.title",
    description: "offering.cost-effective.description",
  },
  {
    icon: <MdEmojiObjects />,
    title: "offering.bespoke.title",
    description: "offering.bespoke.description",
  },
];

const Service = (): React.ReactElement => {
  const { t } = useTranslation();
  const offeringRef = useNav("Offering");

  return (
    <div
      ref={offeringRef}
      id="offeringContainer"
      className="service-area ptb--120 bg_color--1"
    >
      <div className="container">
        <div className="row service-main-wrapper">
          <div className="section-title text-center">
            <h2 className="title">{t("offering.title")}</h2>
          </div>
          {ServiceListOne.map((val, i) => (
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
              <div className="service service__style text-center bg-gray">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h3 className="title">{t(val.title)}</h3>
                  <p className="text-justify">{t(val.description)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
