// Base template CorporateBusiness
import { useEffect, useState, useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

import ScrollTop from "./component/common/ScrollTop";
import Main from "./pages/Main";
import NavProvider from "./context/NavContext";
import Helmet from "./component/common/Helmet";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Modal } from "react-bootstrap";

const cookieConsentStyle = { background: "#2B373B" };
const cookieConsentButtonStyle = { color: "#4e503b", fontSize: "13px" };
ReactGA.initialize("G-EELDHG3LJT");

const App = () => {
  const { t } = useTranslation();
  const [showPrivacy, setShowPrivacy] = useState(false);

  const handleClose = useCallback(() => {
    setShowPrivacy(false);

    ReactGA.event({
      category: "Page",
      action: "close privacy content",
    });
  }, []);

  const handlePrivacy = useCallback(() => {
    setShowPrivacy(true);

    ReactGA.event({
      category: "Page",
      action: "open privacy consent",
    });
  }, []);

  useEffect(() => {
    if (showPrivacy) document.documentElement.classList.add("overflow-hidden");
    else document.documentElement.classList.remove("overflow-hidden");
  }, [showPrivacy]);

  return (
    <BrowserRouter basename={"/"}>
      <>
        <NavProvider>
          <Helmet title="Future MS" />

          <Header
            headerPosition="header--static logoresize"
            color="color-black"
          />

          <Main />

          <Footer />

          <div className="backto-top">
            <ScrollTop />
          </div>

          <CookieConsent
            location="bottom"
            buttonText={t("cookie.banner.accept-button")}
            cookieName="future-ms-banner"
            style={cookieConsentStyle}
            buttonStyle={cookieConsentButtonStyle}
            expires={150}
          >
            <div className="privacy-cookies">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("cookie.banner.content"),
                }}
              />
              <span className="link" onClick={handlePrivacy}>
                {t("cookie.banner.privacy-link")}
              </span>
            </div>
          </CookieConsent>
        </NavProvider>
        <Modal
          size="lg"
          scrollable
          backdrop="static"
          keyboard={false}
          show={showPrivacy}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("privacy.title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            dangerouslySetInnerHTML={{
              __html: t("privacy.content"),
            }}
          />
        </Modal>
      </>
    </BrowserRouter>
  );
};

export default App;
