import React, { MouseEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { IoMdGlobe } from "react-icons/io";
import ReactGA from "react-ga4";

const LangSwitch = (): React.ReactElement => {
  const { t, i18n } = useTranslation();

  const switchLang = useCallback(
    (event: MouseEvent<Element>) => {
      event.preventDefault();
      const selectedLanguage = event.currentTarget.getAttribute("data-value");
      if (selectedLanguage) {
        i18n.changeLanguage(selectedLanguage);

        ReactGA.event({
          category: "Language",
          action: "Change language",
          label: selectedLanguage,
        });
      }
    },
    [i18n]
  );

  const title = <IoMdGlobe />;
  return (
    <DropdownButton
      align="end"
      size="sm"
      id="dropdown-basic-button"
      title={title}
    >
      <Dropdown.Item data-value="pt" onClick={switchLang}>
        {t("language.portuguese")}
      </Dropdown.Item>
      <Dropdown.Item data-value="en" onClick={switchLang}>
        {t("language.english")}
      </Dropdown.Item>
    </DropdownButton>
    // <div className="dropdown">
    //   <button
    //     className="btn btn-bd-light btn-sm dropdown-toggle"
    //     type="button"
    //     id="dropdownMenuButton1"
    //     data-bs-toggle="dropdown"
    //     aria-expanded="false"
    //   >
    //     Dropdown button
    //   </button>

    //   <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    //     <li>
    //       <button
    //         data-value="pt"
    //         className="dropdown-item"
    //         onClick={switchLang}
    //       >
    //         {t("language.portuguese")}
    //       </button>
    //     </li>
    //     <li>
    //       <button
    //         data-value="en"
    //         className="dropdown-item"
    //         onClick={switchLang}
    //       >
    //         {t("language.english")}
    //       </button>
    //     </li>
    //   </ul>
    // </div>
  );
};

export default LangSwitch;
