import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import ReactGA from "react-ga4";

const ScrollTop = (): React.ReactElement => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 250) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 250) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    ReactGA.event({
      category: "Page",
      action: "scroll top",
    });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <FaArrowCircleUp
      className="scrollTop"
      onClick={scrollTop}
      style={{
        fill: "#bebebe",
        color: "#fff",
        width: 40,
        height: 40,
        display: showScroll ? "flex" : "none",
      }}
    />
  );
};
export default ScrollTop;
