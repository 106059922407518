import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import logoWhite from "../../assets/images/logo-white.svg";

type LogoProps = {
  whiteVersion?: boolean;
};

const Logo = ({ whiteVersion = false }: LogoProps): React.ReactElement => (
  <div className="logo">
    <Link to="/">
      <img
        src={whiteVersion ? logoWhite : logo}
        className="App-logo"
        alt="logo"
      />
    </Link>
  </div>
);
export default Logo;
