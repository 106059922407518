import React from "react";
import { useTranslation } from "react-i18next";
import {
  MdPrint,
  MdClearAll,
  MdLaptopMac,
  MdQuestionAnswer,
  MdBorderColor,
} from "react-icons/md";

const ConfidentilityFirstList = [
  {
    icon: <MdPrint />,
    description: "confidentiality-first.print",
  },
  {
    icon: <MdClearAll />,
    description: "confidentiality-first.hard-copies",
  },
  {
    icon: <MdLaptopMac />,
    description: "confidentiality-first.employess-login",
  },
  {
    icon: <MdQuestionAnswer />,
    description: "confidentiality-first.communitactions",
  },
  {
    icon: <MdBorderColor />,
    description: "confidentiality-first.non-disclouse",
  },
];

const ConfidentialityFirst = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="service-area ptb--120 bg_color--5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb--25 mb_sm--0">
              <h2 className="title">{t("confidentiality-first.title")}</h2>
              <span
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: t("confidentiality-first.description"),
                }}
              />
            </div>
          </div>
        </div>

        <div className="row service-main-wrapper">
          {ConfidentilityFirstList.map((val, i) => (
            <div className="col-sm-6 col-12" key={i}>
              <div className="service service__style text-center">
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <p>{t(val.description)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ConfidentialityFirst;
