import React from "react";
import { useTranslation } from "react-i18next";

const AboutArea = (): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="rn-about-area ptb--120 bg_color--1">
      <div className="row row--35">
        <div className="col-lg-12">
          <div className="about-inner inner">
            <div className="section-title text-center">
              <h2 className="title">{t("why-portugal.title")}</h2>
            </div>
            <div className="d-flex align-items-center justify-content-center mt--30 ">
              <ul
                dangerouslySetInnerHTML={{
                  __html: t("why-portugal.description"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutArea;
