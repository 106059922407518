import React from "react";
import { Helmet } from "react-helmet-async";

type PageHelmetProps = {
  title: string;
};

const PageHelmet = (props: PageHelmetProps) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{props.title}</title>
    <meta name="robots" content="noindex, follow" />
    <meta name="description" content="Future ....." />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
  </Helmet>
);

export default PageHelmet;
