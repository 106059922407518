import React from "react";
import { useTranslation } from "react-i18next";
import { useNav } from "../hooks/useNav";
import TeamMember from "../component/TeamMember";

const Team = (): React.ReactElement => {
  const { t } = useTranslation();
  const teamRef = useNav("Team");

  return (
    <div
      ref={teamRef}
      id="teamContainer"
      className="rn-team-area ptb--120 bg_color--5"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb--25 mb_sm--0">
              <h2 className="title">{t("team.title")}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <TeamMember
            column="col-lg-4 col-md-6 col-sm-6 col-12"
            teamStyle="team-style--bottom"
          />
        </div>
      </div>
    </div>
  );
};
export default Team;
