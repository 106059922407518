import React from "react";
import { useTranslation } from "react-i18next";

const LowRiskImplementation = (): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="rn-pricing-table-area ptb--120 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
              <h2 className="title">{t("low-risk.title")}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-12 mt--30">
            <div className="rn-pricing">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">
                    {t("low-risk.introductory-pilot.title")}
                  </h4>
                </div>
                <div className="pricing-body">
                  <ul
                    className="list-style--1"
                    dangerouslySetInnerHTML={{
                      __html: t("low-risk.introductory-pilot.description"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12 mt--30">
            <div className="rn-pricing">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">
                    {t("low-risk.full-time-engagement.title")}
                  </h4>
                </div>
                <div className="pricing-body">
                  <ul
                    className="list-style--1"
                    dangerouslySetInnerHTML={{
                      __html: t("low-risk.full-time-engagement.description"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LowRiskImplementation;
