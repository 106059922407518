import { useCallback, useContext } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { NavContext } from "../context/NavContext";

type NavLinkProps = {
  navLinkKey: string;
  scrollToId: string;
};

const NavLink = (props: NavLinkProps) => {
  const { t } = useTranslation();
  const { navLinkKey, scrollToId } = props;
  const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);

  const handleClick = useCallback(() => {
    setActiveNavLinkId(navLinkKey);
    document.getElementById(scrollToId)?.scrollIntoView({ behavior: "smooth" });
    document.querySelector(".header-wrapper")?.classList.remove("menu-open");
    ReactGA.pageview(scrollToId, [], t(navLinkKey));
  }, [navLinkKey, scrollToId, setActiveNavLinkId, t]);

  return (
    <li>
      <span
        id={navLinkKey}
        className={activeNavLinkId === navLinkKey ? "activeClass" : ""}
        onClick={handleClick}
      >
        {t(navLinkKey)}
      </span>
    </li>
  );
};

export default NavLink;
