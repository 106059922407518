import React from "react";
import { useTranslation } from "react-i18next";
import { useNav } from "../hooks/useNav";

const About = (): React.ReactElement => {
  const aboutRef = useNav("About");
  const { t } = useTranslation();

  return (
    <div
      className="about-area rm-about-style-2 ptb--120 bg_color--5"
      id="aboutContainer"
      ref={aboutRef}
    >
      <div className="about-wrapper">
        <div className="container">
          <div className="row row--20 align-items-center">
            <div className="col-12">
              <div className="about-inner inner">
                <div className="section-title text-center">
                  <h2 className="title">{t("about-us.title")}</h2>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: t("about-us.description"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
