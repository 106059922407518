import React from "react";
import About from "./About";
import AboutArea from "./AboutArea";
import ConfidentialityFirst from "./ConfidentialityFirst";
import Contact from "./Contact";
import Home from "./Home";
import LowRiskImplementation from "./LowRiskImplementation";
import Service from "./Service";
import Team from "./Team";

const Main = (): React.ReactElement => (
  <>
    <Home />

    <Service />

    <About />

    <AboutArea />

    <Team />

    <LowRiskImplementation />

    <ConfidentialityFirst />

    <Contact />
  </>
);

export default Main;
